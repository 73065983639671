var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{},[_c('span',{staticClass:"title"},[_vm._v(" Ventas "+_vm._s(_vm.currencyFormat(_vm.$store.state.pricing.totalVenta)))])]),(_vm.isFlete() && _vm.isImport())?_c('v-expansion-panels',{staticClass:"condensed my-1"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$store.state.pricing.listTipoCostos.length > 0 ? _vm.$store.state.pricing.listTipoCostos.filter( function (v) { return v.codigo == "FL"; } )[0].name : "")+": "),_c('span',[_vm._v(_vm._s(_vm.currencyFormat(_vm.resumenOpcion.flete)))])]),_c('v-expansion-panel-content',[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{},[_vm._v("Conceptos")]),_c('th',{},[_vm._v("Multipicador")]),_c('th',{staticClass:"derecha"},[_vm._v("Costo Unitario")]),_c('th',{},[_vm._v("Sub Total")])])]),_c('tbody',_vm._l((_vm.valores.filter(
                function (v) { return v.esfleteflag == 1 && v.status == 1; }
              )),function(valor,i){return _c('tr',{key:i},[_c('td',[_c('v-text-field',{staticClass:"widthTD",attrs:{"readonly":"","dense":"","hide-details":""},model:{value:(valor.nameservice),callback:function ($$v) {_vm.$set(valor, "nameservice", $$v)},expression:"valor.nameservice"}})],1),_c('td',[_c('v-select',{staticClass:"widthTD",attrs:{"readonly":"","items":_vm.$store.state.pricing.listMultiplicador,"placeholder":"Multiplicador","hide-details":"","dense":""},on:{"change":_vm.calcTotales},model:{value:(valor.id_multiplicador),callback:function ($$v) {_vm.$set(valor, "id_multiplicador", $$v)},expression:"valor.id_multiplicador"}})],1),_c('td',[(
                    _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","dense":"","max-width":"50%","error-messages":valor.error,"prefix":"$","type":"number","step":"0.01","min":valor.minimo},on:{"blur":_vm.calcTotales},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador &&
                        (v.code == 13 || v.code == 14); }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","hide-details":"","max-width":"50%","suffix":"%","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.cif),callback:function ($$v) {_vm.$set(valor, "cif", $$v)},expression:"valor.cif"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador && v.code == 5; }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","max-width":"50%","suffix":"%","hide-details":"","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.seguro),callback:function ($$v) {_vm.$set(valor, "seguro", $$v)},expression:"valor.seguro"}}):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e()],1),(
                  _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( (_vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].valor : 0) * valor.costounitario * _vm.calcularFac( _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "N", _vm.$store.state.pricing.datosPrincipales.volumen, _vm.$store.state.pricing.datosPrincipales.peso, _vm.$store.state.pricing.datosPrincipales.containers, _vm.$store.state.pricing.datosPrincipales.amount ) ))+" ")]):_vm._e(),(
                  !_vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.calcularValor( _vm.$store.state.pricing.datosPrincipales.amount, _vm.$store.state.pricing.totalFlete, _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "", _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13 || v.code == 5); } ) ? _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13); } ) ? valor.cif : valor.seguro : 0 ) ))+" ")]):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoConfeccion(valor))+" ")]):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoITBM(valor))+" ")]):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoNotaCredito(valor))+" ")]):_vm._e()])}),0)])],1)],1)],1):_vm._e(),(_vm.isOrigen())?_c('v-expansion-panels',{staticClass:"condensed my-1"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$store.state.pricing.listTipoCostos.length > 0 ? _vm.$store.state.pricing.listTipoCostos.filter( function (v) { return v.codigo == "OR"; } )[0].name : "")+": "),_c('span',[_vm._v(_vm._s(_vm.currencyFormat(_vm.resumenOpcion.origen)))])]),_c('v-expansion-panel-content',[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{},[_vm._v("Conceptos")]),_c('th',{},[_vm._v("Multipicador")]),_c('th',{staticClass:"derecha"},[_vm._v("Costo Unitario")]),_c('th',{},[_vm._v("Sub Total")])])]),_c('tbody',_vm._l((_vm.valores.filter(
                function (v) { return v.esorigenflag == 1 && v.status == 1; }
              )),function(valor,i){return _c('tr',{key:i},[_c('td',[_c('v-text-field',{staticClass:"widthTD",attrs:{"readonly":"","dense":"","hide-details":""},model:{value:(valor.nameservice),callback:function ($$v) {_vm.$set(valor, "nameservice", $$v)},expression:"valor.nameservice"}})],1),_c('td',[_c('v-select',{staticClass:"widthTD",attrs:{"readonly":"","items":_vm.$store.state.pricing.listMultiplicador,"placeholder":"Multiplicador","hide-details":"","dense":""},on:{"change":_vm.calcTotales},model:{value:(valor.id_multiplicador),callback:function ($$v) {_vm.$set(valor, "id_multiplicador", $$v)},expression:"valor.id_multiplicador"}})],1),_c('td',[(
                    _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","dense":"","max-width":"50%","error-messages":valor.error,"prefix":"$","type":"number","step":"0.01","min":valor.minimo},on:{"blur":_vm.calcTotales},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador &&
                        (v.code == 13 || v.code == 14); }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","hide-details":"","max-width":"50%","suffix":"%","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.cif),callback:function ($$v) {_vm.$set(valor, "cif", $$v)},expression:"valor.cif"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador && v.code == 5; }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","max-width":"50%","suffix":"%","hide-details":"","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.seguro),callback:function ($$v) {_vm.$set(valor, "seguro", $$v)},expression:"valor.seguro"}}):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e()],1),(
                  _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( (_vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].valor : 0) * valor.costounitario * _vm.calcularFac( _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "N", _vm.$store.state.pricing.datosPrincipales.volumen, _vm.$store.state.pricing.datosPrincipales.peso, _vm.$store.state.pricing.datosPrincipales.containers, _vm.$store.state.pricing.datosPrincipales.amount ) ))+" ")]):_vm._e(),(
                  !_vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.calcularValor( _vm.$store.state.pricing.datosPrincipales.amount, _vm.$store.state.pricing.totalFlete, _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "", _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13 || v.code == 5); } ) ? _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13); } ) ? valor.cif : valor.seguro : 0 ) ))+" ")]):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoConfeccion(valor))+" ")]):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoITBM(valor))+" ")]):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoNotaCredito(valor))+" ")]):_vm._e()])}),0)])],1)],1)],1):_vm._e(),(_vm.isLocal())?_c('v-expansion-panels',{staticClass:"condensed my-1"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$store.state.pricing.listTipoCostos.length > 0 ? _vm.$store.state.pricing.listTipoCostos.filter( function (v) { return v.codigo == "LO"; } )[0].name : "")+": "),_c('span',[_vm._v(_vm._s(_vm.currencyFormat(_vm.resumenOpcion.gasto)))])]),_c('v-expansion-panel-content',[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{},[_vm._v("Conceptos")]),_c('th',{},[_vm._v("Multipicador")]),_c('th',{staticClass:"derecha"},[_vm._v("Costo Unitario")]),_c('th',{},[_vm._v("Sub Total")])])]),_c('tbody',_vm._l((_vm.valores.filter(
                function (v) { return v.eslocalflag == 1 && v.status == 1; }
              )),function(valor,i){return _c('tr',{key:i},[_c('td',[_c('v-text-field',{staticClass:"widthTD",attrs:{"readonly":"","dense":"","hide-details":""},model:{value:(valor.nameservice),callback:function ($$v) {_vm.$set(valor, "nameservice", $$v)},expression:"valor.nameservice"}})],1),_c('td',[_c('v-select',{staticClass:"widthTD",attrs:{"readonly":"","items":_vm.$store.state.pricing.listMultiplicador,"placeholder":"Multiplicador","hide-details":"","dense":""},on:{"change":_vm.calcTotales},model:{value:(valor.id_multiplicador),callback:function ($$v) {_vm.$set(valor, "id_multiplicador", $$v)},expression:"valor.id_multiplicador"}})],1),_c('td',[(
                    _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","dense":"","max-width":"50%","error-messages":valor.error,"prefix":"$","type":"number","step":"0.01","min":valor.minimo},on:{"blur":_vm.calcTotales},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador &&
                        (v.code == 13 || v.code == 14); }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","hide-details":"","max-width":"50%","suffix":"%","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.cif),callback:function ($$v) {_vm.$set(valor, "cif", $$v)},expression:"valor.cif"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador && v.code == 5; }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","max-width":"50%","suffix":"%","hide-details":"","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.seguro),callback:function ($$v) {_vm.$set(valor, "seguro", $$v)},expression:"valor.seguro"}}):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e()],1),(
                  _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( (_vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].valor : 0) * valor.costounitario * _vm.calcularFac( _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "N", _vm.$store.state.pricing.datosPrincipales.volumen, _vm.$store.state.pricing.datosPrincipales.peso, _vm.$store.state.pricing.datosPrincipales.containers, _vm.$store.state.pricing.datosPrincipales.amount ) ))+" ")]):_vm._e(),(
                  !_vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.calcularValor( _vm.$store.state.pricing.datosPrincipales.amount, _vm.$store.state.pricing.totalFlete, _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "", _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13 || v.code == 5); } ) ? _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13); } ) ? valor.cif : valor.seguro : 0 ) ))+" ")]):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoConfeccion(valor))+" ")]):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoITBM(valor))+" ")]):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoNotaCredito(valor))+" ")]):_vm._e()])}),0)])],1)],1)],1):_vm._e(),(_vm.isAduana())?_c('v-expansion-panels',{staticClass:"condensed my-1"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$store.state.pricing.listTipoCostos.length > 0 ? _vm.$store.state.pricing.listTipoCostos.filter( function (v) { return v.codigo == "AD"; } )[0].name : "")+": "),_c('span',[_vm._v(_vm._s(_vm.currencyFormat(_vm.resumenOpcion.aduana)))])]),_c('v-expansion-panel-content',[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{},[_vm._v("Conceptos")]),_c('th',{},[_vm._v("Multipicador")]),_c('th',{staticClass:"derecha"},[_vm._v("Costo Unitario")]),_c('th',{},[_vm._v("Sub Total")])])]),_c('tbody',_vm._l((_vm.valores.filter(
                function (v) { return v.esaduanaflag == 1 && v.status == 1; }
              )),function(valor,i){return _c('tr',{key:i},[_c('td',[_c('v-text-field',{staticClass:"widthTD",attrs:{"readonly":"","dense":"","hide-details":""},model:{value:(valor.nameservice),callback:function ($$v) {_vm.$set(valor, "nameservice", $$v)},expression:"valor.nameservice"}})],1),_c('td',[_c('v-select',{staticClass:"widthTD",attrs:{"readonly":"","items":_vm.$store.state.pricing.listMultiplicador,"placeholder":"Multiplicador","hide-details":"","dense":""},on:{"change":_vm.calcTotales},model:{value:(valor.id_multiplicador),callback:function ($$v) {_vm.$set(valor, "id_multiplicador", $$v)},expression:"valor.id_multiplicador"}})],1),_c('td',[(
                    _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","dense":"","max-width":"50%","error-messages":valor.error,"prefix":"$","type":"number","step":"0.01","min":valor.minimo},on:{"blur":_vm.calcTotales},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador &&
                        (v.code == 13 || v.code == 14); }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","hide-details":"","max-width":"50%","suffix":"%","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.cif),callback:function ($$v) {_vm.$set(valor, "cif", $$v)},expression:"valor.cif"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador && v.code == 5; }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","max-width":"50%","suffix":"%","hide-details":"","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.seguro),callback:function ($$v) {_vm.$set(valor, "seguro", $$v)},expression:"valor.seguro"}}):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e()],1),(
                  _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( (_vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].valor : 0) * valor.costounitario * _vm.calcularFac( _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "N", _vm.$store.state.pricing.datosPrincipales.volumen, _vm.$store.state.pricing.datosPrincipales.peso, _vm.$store.state.pricing.datosPrincipales.containers, _vm.$store.state.pricing.datosPrincipales.amount ) ))+" ")]):_vm._e(),(
                  !_vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.calcularValor( _vm.$store.state.pricing.datosPrincipales.amount, _vm.$store.state.pricing.totalFlete, _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador; } ) ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "", _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13 || v.code == 5); } ) ? _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13); } ) ? valor.cif : valor.seguro : 0 ) ))+" ")]):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoConfeccion(valor))+" ")]):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoITBM(valor))+" ")]):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoNotaCredito(valor))+" ")]):_vm._e()])}),0)])],1)],1)],1):_vm._e(),(_vm.isFlete() && !_vm.isImport())?_c('v-expansion-panels',{staticClass:"condensed my-1"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$store.state.pricing.listTipoCostos.length > 0 ? _vm.$store.state.pricing.listTipoCostos.filter( function (v) { return v.codigo == "FL"; } )[0].name : "")+": "),_c('span',[_vm._v(_vm._s(_vm.currencyFormat(_vm.resumenOpcion.flete)))])]),_c('v-expansion-panel-content',[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{},[_vm._v("Conceptos")]),_c('th',{},[_vm._v("Multipicador")]),_c('th',{staticClass:"derecha"},[_vm._v("Costo Unitario")]),_c('th',{},[_vm._v("Sub Total")])])]),_c('tbody',_vm._l((_vm.valores.filter(
                function (v) { return v.esfleteflag == 1 && v.status == 1; }
              )),function(valor,i){return _c('tr',{key:i},[_c('td',[_c('v-text-field',{staticClass:"widthTD",attrs:{"readonly":"","dense":"","hide-details":""},model:{value:(valor.nameservice),callback:function ($$v) {_vm.$set(valor, "nameservice", $$v)},expression:"valor.nameservice"}})],1),_c('td',[_c('v-select',{staticClass:"widthTD",attrs:{"readonly":"","items":_vm.$store.state.pricing.listMultiplicador,"placeholder":"Multiplicador","hide-details":"","dense":""},on:{"change":function($event){return _vm.recalcularCostos()}},model:{value:(valor.id_multiplicador),callback:function ($$v) {_vm.$set(valor, "id_multiplicador", $$v)},expression:"valor.id_multiplicador"}})],1),_c('td',[(
                    _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","dense":"","max-width":"50%","error-messages":valor.error,"prefix":"$","type":"number","step":"0.01","min":valor.minimo},on:{"blur":function($event){return _vm.recalcularCostos()}},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador &&
                        (v.code == 13 || v.code == 14); }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","hide-details":"","max-width":"50%","suffix":"%","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.recalcularCostos},model:{value:(valor.cif),callback:function ($$v) {_vm.$set(valor, "cif", $$v)},expression:"valor.cif"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador && v.code == 5; }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","max-width":"50%","suffix":"%","hide-details":"","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.recalcularCostos},model:{value:(valor.seguro),callback:function ($$v) {_vm.$set(valor, "seguro", $$v)},expression:"valor.seguro"}}):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e()],1),(
                  _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( (_vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].valor : 0) * valor.costounitario * _vm.calcularFac( _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "N", _vm.$store.state.pricing.datosPrincipales.volumen, _vm.$store.state.pricing.datosPrincipales.peso, _vm.$store.state.pricing.datosPrincipales.containers, _vm.$store.state.pricing.datosPrincipales.amount ) ))+" ")]):_vm._e(),(
                  !_vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.calcularValor( _vm.$store.state.pricing.datosPrincipales.amount, _vm.$store.state.pricing.totalFlete, _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "", _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13 || v.code == 5); } ) ? _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13); } ) ? valor.cif : valor.seguro : 0 ) ))+" ")]):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoConfeccion(valor))+" ")]):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoITBM(valor))+" ")]):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoNotaCredito(valor))+" ")]):_vm._e()])}),0)])],1)],1)],1):_vm._e(),(_vm.isAlmacen())?_c('v-expansion-panels',{staticClass:"condensed my-1"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$store.state.pricing.listTipoCostos.length > 0 ? _vm.$store.state.pricing.listTipoCostos.filter( function (v) { return v.codigo == "AL"; } )[0].name : "")+": "),_c('span',[_vm._v(_vm._s(_vm.currencyFormat(_vm.resumenOpcion.almacen)))])]),_c('v-expansion-panel-content',[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{}),_c('th',{},[_vm._v("Conceptos")]),_c('th',{},[_vm._v("Multipicador")]),_c('th',{staticClass:"derecha"},[_vm._v("Costo Unitario")]),_c('th',{},[_vm._v("Sub Total")])])]),_c('tbody',_vm._l((_vm.valores.filter(
                function (v) { return v.esalmacenflag == 1 && v.status == 1; }
              )),function(valor,i){return _c('tr',{key:i},[_c('td',[_c('v-text-field',{staticClass:"widthTD",attrs:{"readonly":"","dense":"","hide-details":""},model:{value:(valor.nameservice),callback:function ($$v) {_vm.$set(valor, "nameservice", $$v)},expression:"valor.nameservice"}})],1),_c('td',[_c('v-select',{staticClass:"widthTD",attrs:{"readonly":"","items":_vm.$store.state.pricing.listMultiplicador,"placeholder":"Multiplicador","hide-details":"","dense":""},on:{"change":_vm.calcTotales},model:{value:(valor.id_multiplicador),callback:function ($$v) {_vm.$set(valor, "id_multiplicador", $$v)},expression:"valor.id_multiplicador"}})],1),_c('td',[(
                    _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","dense":"","max-width":"50%","error-messages":valor.error,"prefix":"$","type":"number","step":"0.01","min":valor.minimo},on:{"blur":_vm.calcTotales},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador &&
                        (v.code == 13 || v.code == 14); }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","hide-details":"","max-width":"50%","suffix":"%","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.cif),callback:function ($$v) {_vm.$set(valor, "cif", $$v)},expression:"valor.cif"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador && v.code == 5; }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","max-width":"50%","suffix":"%","hide-details":"","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.seguro),callback:function ($$v) {_vm.$set(valor, "seguro", $$v)},expression:"valor.seguro"}}):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e()],1),(
                  _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( (_vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].valor : 0) * valor.costounitario * _vm.calcularFac( _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "N", _vm.$store.state.pricing.datosPrincipales.volumen, _vm.$store.state.pricing.datosPrincipales.peso, _vm.$store.state.pricing.datosPrincipales.containers, _vm.$store.state.pricing.datosPrincipales.amount ) ))+" ")]):_vm._e(),(
                  !_vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.calcularValor( _vm.$store.state.pricing.datosPrincipales.amount, _vm.$store.state.pricing.totalFlete, _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "", _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13 || v.code == 5); } ) ? _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13); } ) ? valor.cif : valor.seguro : 0 ) ))+" ")]):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoConfeccion(valor))+" ")]):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoITBM(valor))+" ")]):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoNotaCredito(valor))+" ")]):_vm._e()])}),0)])],1)],1)],1):_vm._e(),(_vm.isGastosTercero())?_c('v-expansion-panels',{staticClass:"condensed my-1"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$store.state.pricing.listTipoCostos.length > 0 ? _vm.$store.state.pricing.listTipoCostos.filter( function (v) { return v.codigo == "GT"; } )[0].name : "")+": "),_c('span',[_vm._v(_vm._s(_vm.currencyFormat(_vm.resumenOpcion.gastostercero)))])]),_c('v-expansion-panel-content',[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{}),_c('th',{},[_vm._v("Conceptos")]),_c('th',{},[_vm._v("Multipicador")]),_c('th',{staticClass:"derecha"},[_vm._v("Costo Unitario")]),_c('th',{},[_vm._v("Sub Total")])])]),_c('tbody',_vm._l((_vm.valores.filter(
                function (v) { return v.esgastostercerosflag == 1 && v.status == 1; }
              )),function(valor,i){return _c('tr',{key:i},[_c('td',[_c('v-text-field',{staticClass:"widthTD",attrs:{"readonly":"","dense":"","hide-details":""},model:{value:(valor.nameservice),callback:function ($$v) {_vm.$set(valor, "nameservice", $$v)},expression:"valor.nameservice"}})],1),_c('td',[_c('v-select',{staticClass:"widthTD",attrs:{"readonly":"","items":_vm.$store.state.pricing.listMultiplicador,"placeholder":"Multiplicador","hide-details":"","dense":""},on:{"change":_vm.calcTotales},model:{value:(valor.id_multiplicador),callback:function ($$v) {_vm.$set(valor, "id_multiplicador", $$v)},expression:"valor.id_multiplicador"}})],1),_c('td',[(
                    _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","dense":"","max-width":"50%","error-messages":valor.error,"prefix":"$","type":"number","step":"0.01","min":valor.minimo},on:{"blur":_vm.calcTotales},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador &&
                        (v.code == 13 || v.code == 14); }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","hide-details":"","max-width":"50%","suffix":"%","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.cif),callback:function ($$v) {_vm.$set(valor, "cif", $$v)},expression:"valor.cif"}}):_vm._e(),(
                    _vm.$store.state.pricing.listMultiplicador.some(
                      function (v) { return v.id == valor.id_multiplicador && v.code == 5; }
                    ) &&
                    !_vm.isITBM(valor.code_cost) &&
                    !_vm.isConfeccion(valor.code_cost) &&
                    !_vm.isNotaCredito(valor.code_cost)
                  )?_c('v-text-field',{staticClass:"derecha tdMontos",attrs:{"readonly":"","max-width":"50%","suffix":"%","hide-details":"","dense":"","max":"100","type":"number","min":valor.minimo,"step":"0.01"},on:{"blur":_vm.calcTotales},model:{value:(valor.seguro),callback:function ($$v) {_vm.$set(valor, "seguro", $$v)},expression:"valor.seguro"}}):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","error-messages":valor.error,"prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('v-text-field',{attrs:{"readonly":"","dense":"","prefix":"$"},model:{value:(valor.costounitario),callback:function ($$v) {_vm.$set(valor, "costounitario", $$v)},expression:"valor.costounitario"}}):_vm._e()],1),(
                  _vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( (_vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].valor : 0) * valor.costounitario * _vm.calcularFac( _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "N", _vm.$store.state.pricing.datosPrincipales.volumen, _vm.$store.state.pricing.datosPrincipales.peso, _vm.$store.state.pricing.datosPrincipales.containers, _vm.$store.state.pricing.datosPrincipales.amount ) ))+" ")]):_vm._e(),(
                  !_vm.isNotPorcentaje(valor, valor.id_multiplicador) &&
                  !_vm.isConfeccion(valor.code_cost) &&
                  !_vm.isITBM(valor.code_cost) &&
                  !_vm.isNotaCredito(valor.code_cost)
                )?_c('td',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.calcularValor( _vm.$store.state.pricing.datosPrincipales.amount, _vm.$store.state.pricing.totalFlete, _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } ).length > 0 ? _vm.$store.state.pricing.listMultiplicador.filter( function (v) { return v.id == valor.id_multiplicador; } )[0].code : "", _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13 || v.code == 5); } ) ? _vm.$store.state.pricing.listMultiplicador.some( function (v) { return v.id == valor.id_multiplicador && (v.code == 14 || v.code == 13); } ) ? valor.cif : valor.seguro : 0 ) ))+" ")]):_vm._e(),(_vm.isConfeccion(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoConfeccion(valor))+" ")]):_vm._e(),(_vm.isITBM(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoITBM(valor))+" ")]):_vm._e(),(_vm.isNotaCredito(valor.code_cost))?_c('td',[_vm._v(" "+_vm._s(_vm.montoNotaCredito(valor))+" ")]):_vm._e()])}),0)])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }